<template>
  <div class="m-taomlar">
    <div class="container">
      <div class="row custom-row">
        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 custom-col2">
          <dashboard></dashboard>
        </div>
        <div class="col-xl-8 col-lg-8 col-md-6 col-sm-6 col-12 custom-col2">
          <div class="manager-food-menu">
            <div class="for-back">
              <router-link to="/dashboard/eats">
                <img src="../../../assets/images/icons/left.svg" alt="" />
                <span>{{ $t("Taom qo‘shish") }}</span>
              </router-link>
            </div>
            <form class="eat-form">
              <div class="row cus-row2">
                <div class="col-xl-6 col-lg-6 col-12 cus-col3">
                  <div class="row cus-row">
                    <div class="col-xl-6 col-6 cus-col">
                      <label for="eatUpload" class="up-images my-up-div">
                        <input type="file" id="eatUpload" class="up-input" />
                        <div class="up-input-fake">
                          <img
                            src="../../../assets/images/icons/cam.svg"
                            alt=""
                          />
                        </div>
                        <span>{{ $t("Taom rasmini yuklash") }}</span>
                      </label>
                    </div>
                    <div class="col-xl-6 col-6 cus-col">
                      <div class="up-images">
                        <img
                          src="../../../assets/images/png/up-img.png"
                          alt=""
                          class="up-img"
                        />
                      </div>
                    </div>
                    <div class="col-xl-6 col-6 cus-col">
                      <div class="up-images">
                        <img
                          src="../../../assets/images/png/up-img.png"
                          alt=""
                          class="up-img"
                        />
                      </div>
                    </div>
                    <div class="col-xl-6 col-6 cus-col">
                      <div class="up-images">
                        <img
                          src="../../../assets/images/png/up-img.png"
                          alt=""
                          class="up-img"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-6 col-lg-6 col-12 cus-col3">
                  <div class="m-eat-form">
                    <input
                      class="e-name"
                      :placeholder="$t('Taom nomini kiriting')"
                    />
                    <textarea
                      class="e-text-area"
                      :placeholder="$t('Taom matni')"
                    ></textarea>
                    <div class="buttons">
                      <input
                        type="button"
                        :value="$t('Bekor qilish')"
                        class="cancel-btn"
                      />
                      <input type="submit" :value="$t('Saqlash')" class="submit-btn" />
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Dashboard from "../../main/Dashboard.vue";
export default {
  components: { Dashboard },
};
</script>

<style>
</style>